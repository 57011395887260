import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandsWash } from "@fortawesome/free-solid-svg-icons/faHandsWash"

export default function footer() {
  return (
    <div>
      <footer className="w-full footer relative pt-1 bottom-0">
        <div className="container mx-auto px-6">
          <div className="mt-16 flex flex-col items-center">
            <div className="sm:w-2/3 text-center py-6">
              <p className="text-orange-700 font-bold mb-2">
                Made with <a href="https://koffeestudios.com"
                             className="hover:no-underline hover:text-orange-900 transition-all duration-300">Koffee</a>. <span
                className="text-white">Don't forget to wash your hands <FontAwesomeIcon icon={faHandsWash}/>.</span>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
