import React from "react"
import "../style.css"
import Appbar from "../components/appbar"
import Footer from "../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import RateForm from "../components/rate-form"
import Intent from "../components/link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons"

function Mug() {

  const today = new Date().getDate();
  const mug = today === 10 ? "mug6.jpeg" : "mug5.jpg"

  const data = useStaticQuery(graphql`
      query {
          file(relativePath: { eq: "mug7.jpeg" }) {
              childImageSharp {
                  # Specify a fixed image and fragment.
                  # The default width is 400 pixels
                  fluid (maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  const ratingClosed = true
  // const ratingClosed = today === 10


  return (
    <div className="container-fluid">
      <Appbar/>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Current Mug | Rate Out of 10</title>
      </Helmet>
      <div className="container flex flex-col">
        <h2 className="mx-auto mt-4 mb-8 font-weight-bold text-5xl text-orange-600 uppercase">
          Sunday 12th April 2020
        </h2>
        <br/>
        <Img className="mx-auto w-1/2"
             fluid={data.file.childImageSharp.fluid}
             alt="My amazing mug"
        />
        <p className="mx-auto mt-4 text-orange-400">
          The <span className="text-orange-600">Hacking for Humanity</span> mug was collected when the team '99' participated and won the 2019 Girls-In-Tech hackathon.
        </p>
        <div className="my-4 mx-auto">
          {ratingClosed ?
            <h3 className="mx-auto mt-4 mb-8 font-weight-bold text-3xl text-orange-600 uppercase">
              <Intent to="/previous-mugs">Rating is not yet opened!</Intent>
            </h3>
            :
            <RateForm/>
          }
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Mug
