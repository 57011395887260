import React from "react"
import Intent from "./link"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

function Appbar() {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid (maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
      <div className="container my-3">

        <ul className="nav justify-content-center items-center uppercase">
          <div className="navbar-brand">
            <Img className="w-32"
              fluid={image.file.childImageSharp.fluid} />
          </div>
          <li className="nav-item mx-4">
            <Intent to="/">Home</Intent>
          </li>
          <li className="nav-item mx-4">
            <Intent to="/mug">Current Mug</Intent>
          </li>
          <li className="nav-item mx-4">
            <Intent to="/previous-mugs">Previous Mugs</Intent>
          </li>
        </ul>
      </div>
    )


}
export default Appbar
