import React, { Component } from "react"

class RateForm extends Component {
  render() {
    return (
      <div>
        <form name="rate" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/success-rate">
          <input type="hidden" name="bot-field"/>
          <input type="hidden" name="form-name" value="rate"/>
          <div className="form-group">
            <div className="row">
              <div className="col">
                <label className="block text-gray-500 font-bold mb-1 mb-0 pr-4" htmlFor="name">
                  Name
                </label>
                <input
                  className="form-control-sm bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-orange-500"
                  type="text" name="name" id="name"/>
              </div>
              <div className="col">
                <label className="block text-gray-500 font-bold mb-1 mb-0 pr-4" htmlFor="score">
                  Score
                </label>
                <input
                  className="form-control-sm bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-orange-500"
                  type="number" name="score" id="score" max={10}/>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="block text-gray-500 font-bold mb-1 mb-0 pr-4" htmlFor="message">
              Message
            </label>
            <textarea
              className="form-control bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-orange-500"
              rows="4" name="message" id="message"/>
          </div>
          <div className="flex items-center content-center w-full mx-auto">
            <button
              className="shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded mx-auto"
              type="submit" value="Rate Mug">
              Rate Mug
            </button>
          </div>
        </form>
      </div>
    )
  }
}

export default RateForm
