import React from "react"
import Link from "gatsby-plugin-transition-link/AniLink"

export default function Intent({ children, to }) {
  return (
    <Link
      paintDrip
      className="px-3"
      duration={0.5}
      hex="#ff8a00"
      to={to}
    >
      {children}
    </Link>
  )
}
